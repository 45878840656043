import React from "react"
import ReactMarkdown from "react-markdown"

import Header from "../components/header"
import Footer from "../components/footer"

import arrow from "../images/arrow-left-blue.svg"

const ArticleTemplate = ({ pageContext }) => {
  const article = pageContext;

  return <div>
    <Header/>

    {article.Splash &&
      <div className="content-pad article-splash"
        style={{backgroundImage: `url("${article.Splash.localFile.url}")`}}
      >
        <div className="article">
          <h1>{article.Title}</h1>
          <span className="small-text">{article.Author}</span>
        </div>
      </div>
    }

    <div className="content-pad white-bg grey-bg">
      <div className="article">
        {!article.Splash && <h1>{article.Title}</h1>}
        {!article.Splash && <span className="small-text">{article.Author}</span>}
        
        <ReactMarkdown>{article.Content.data.Content}</ReactMarkdown>

        <a className="go-to-site" href="/talk/"><img src={arrow}/> Torna agli articoli</a>
      </div>
    </div>

    <Footer/>
  </div>
}

export default ArticleTemplate
